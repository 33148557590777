import { AUTH_FETCH } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case AUTH_FETCH:
      return {
        user: action.user
      };
    default:
      return state
  }
}
