import { PROPERTY_DETAIL_FETCH } from '../actions/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = [], action) {
  switch (action.type) {
    case PROPERTY_DETAIL_FETCH:
      return {
        statusCode: action.statusCode,
        data: action.data,
      }
    default:
      return state
  }
}
