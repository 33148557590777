import { combineReducers } from 'redux'
import HomeReducer from './homeReducer'
import PropertyDetailReducer from './PropertyDetailReducer'
import authReducer from './authReducer'

const rootReducer = combineReducers({
  home: HomeReducer,
  auth: authReducer,
  propertyDetail: PropertyDetailReducer,
})

export default rootReducer
