import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, useLocation, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useRef } from 'react'

const Header = loadable(() => import('./Header'))
const Footer = loadable(() => import('./Footer'))

const Home = loadable(() => import('../pages/Home'), {
  fallback: <div>MUVFAST</div>,
})
const Property = loadable(() => import('../pages/Property'))
const About = loadable(() => import('../pages/About'))
const ManageProperty = loadable(() => import('../pages/ManageProperty'))
const CreateProperty = loadable(() => import('../pages/CreateProperty'))
const OfferDetail = loadable(() => import('../pages/OfferDetail'))
const ProjectDetail = loadable(() => import('../pages/PropertyDetail'))
const PropertyDetails = loadable(() => import('../pages/PropertyDetails'))
const Profile = loadable(() => import('../pages/Profile'))

function _ScrollToTop(props) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

export const AuthenContext = React.createContext()
const App = () => {
  const loginButtonRef = useRef(null)
  return (
    <AuthenContext.Provider value={{ loginButtonRef }}>
      <BrowserRouter>
        {!window.location.href.endsWith('/property') && <Header loginButtonRef={loginButtonRef} />}
        <ScrollToTop>
          <main>
            <Switch>
              <Route path="/property/:propertyId" component={PropertyDetails} />
              <Route path="/property" component={Property} />
              <Route path="/about" component={About} />
              <Route path="/project/:name" component={ProjectDetail} />
              <Route
                path="/manage-property"
                component={(routeProps) => <ManageProperty router={routeProps} />}
              />
              <Route
                path="/edit-property/:id"
                component={(routeProps) => <CreateProperty router={routeProps} />}
              />
              <Route
                path="/create-property"
                component={(routeProps) => <CreateProperty router={routeProps} />}
              />
              <Route
                path="/offer-detail/:propertyId"
                component={(routeProps) => <OfferDetail router={routeProps} />}
              />
              <Route
                path="/profile"
                component={(routeProps) => <Profile router={routeProps} />}
              />
              <Route path="/" component={Home} />
            </Switch>
          </main>
        </ScrollToTop>
        {!window.location.href.includes('/create-property') &&
          !window.location.href.includes('/manage-property') && <Footer />}
      </BrowserRouter>
    </AuthenContext.Provider>
  )
}

export default App
