import { HOME_FETCH } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case HOME_FETCH:
      return {
        statusCode: action.statusCode,
        data: action.data
      };
    default:
      return state
  }
}
